import { render, staticRenderFns } from "./tradingHour.vue?vue&type=template&id=79f4b829"
import script from "./tradingHour.vue?vue&type=script&lang=js"
export * from "./tradingHour.vue?vue&type=script&lang=js"
import style0 from "./tradingHour.vue?vue&type=style&index=0&id=79f4b829&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports